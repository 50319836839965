<template>
    <div class="index">
          <a-form  >
            <a-form-item label="推送地址" ><a-input placeholder="推送地址" v-model="params.pushUrl" allowClear  /></a-form-item>
            <a-form-item label="设备编号" ><a-input placeholder="设备编号" v-model="params.deviceCode" allowClear  /></a-form-item>
            <a-form-item label="推送条数" ><a-input-number   v-model="params.count" /></a-form-item>
            
            <a-form-item>
              <a-button type="primary" @click="onPush">  推送 </a-button>
              <a-button type="primary" @click="onReset" style="margin-left: 10px;" >  重置 </a-button>
            </a-form-item>
        </a-form>
        <a-list :grid="{ gutter: 16, column: 4 }" :data-source="result" :pagination="pagination">
            <a-list-item slot="renderItem" slot-scope="item, index">
                <a-card :title="item.deviceCode">
                    <h4>垃圾桶编号：{{item.trashCode}}</h4>
                    <h4>垃圾类别：{{item.typeCode}}</h4>
                    <h4>重量：{{item.weight}}kg</h4>

                </a-card>
            </a-list-item>
        </a-list>
    </div>
</template>

<script>
import {  devicePushApi  } from '@/api/actual'
 
export default {
  data() {
    return {
       params: {
            deviceCode: '',
            pushUrl: '',
            count: 0
       },
       result: [],
        pagination: {
            onChange: page => {
                console.log(page);
            },
            pageSize: 4,
        },
    };
  },
  mounted() {
  
  },
  methods: {
    async onPush() {
        this.result = (await devicePushApi({...this.params})).result
        this.$message.success('推送成功')

    },
    onReset() {
        this.params = {
            deviceCode: '',
            pushUrl: '',
            count: 0

        }
    }
  }
};
</script>

<style lang="scss">
.index{
    /* background: #F5F7F9; */
    padding: 15px;
    box-sizing: border-box;
    width: 100%;
    min-height: 100%;
}
.ant-form{
    text-align: initial;
}
.actual-img{
  width: 80px;
  height: 40px;
  margin: auto;
  background: #1890ff;
  color: #fff;
  font-weight: bold;
  font-size: 14px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.preview-image-modal{
  .ant-modal-content {
    position: relative;
    background-color: transparent;
    background-clip: padding-box;
    border: 0;
    border-radius: 4px;
    -webkit-box-shadow: 0px !important;
    box-shadow: 0 0px 0px #fff !important;
  }
}

</style>